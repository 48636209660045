module.exports = {
  title: `kubica.dev`,
  description: `Blog o technikaliach, językach programowania i wszystkim co dotyczy świata IT`,
  author: `Robert Kubica`,
  introduction: `"Dochodzenie do mistrzostwa wymaga dwóch elementów: wiedzy i pracy." Robert Cecil Martin`,
  siteUrl: `https://kubica.dev`, // Your blog site url
  social: {
    twitter: `@robertqbica`, // Your Twitter account
    github: `haxcode`, // Your GitHub account
    medium: ``, // Your Medium account
    facebook: ``, // Your Facebook account
  },
  icon: `content/assets/felog.svg`, // Add your favicon
  keywords: [`blog`],
  comment: {
    disqusShortName: 'kubica-dev', // Your disqus-short-name. check disqus.com.
    utterances: '', // Your repository for archive comment
  },
  configs: {
    countOfInitialPost: 10, // Config your initial count of post
  },
  sponsor: {
    buyMeACoffeeId: '',
  },
  share: {
    facebookAppId: '', // Add facebookAppId for using facebook share feature v3.2
  },
  ga: 'UA-150667717-1', // Add your google analytics tranking ID
};
